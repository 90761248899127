@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,400,400italic,600");
@import url("./fontawesome-all.min.css");
@import url('https://fonts.googleapis.com/css?family=Exo&display=swap');


html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, output, ruby,
section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, section {
  display: block;
}

span {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
}

span a {
  color: #fff;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input, select, textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

/* Basic */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background: #ffffff;
  color: #00112B;
  font-size: 15pt;
  line-height: 1.85em;
  font-family: 'Exo', 'Source Sans Pro', sans-serif;
  font-weight: 300;
}

#animation-container {
  width: 100%;
  height: 100%;
}

body.is-preload *, body.is-preload *:before, body.is-preload *:after {
  -moz-animation: none !important;
  -webkit-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

input, textarea, select {
  font-size: 15pt;
  line-height: 1.85em;
  font-family: 'Exo', 'Source Sans Pro', sans-serif;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  color: #2C4570;
  line-height: 1.25em;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  text-decoration: none;
  border-bottom-color: transparent;
}

h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
  font-weight: 600;
}

h2 {
  font-size: 2.85em;
}

h3 {
  font-size: 1.25em;
}

h4 {
  font-size: 1em;
  margin: 0 0 0.25em 0;
}

strong, b {
  font-weight: 400;
  color: #2C4570;
}

em, i {
  font-style: italic;
}

a {
  color: inherit;
  border-bottom: solid 1px rgba(128, 128, 128, 0.15);
  text-decoration: none;
  -moz-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
  -webkit-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
  -ms-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
}

a:hover {
  color: #2C4570;
  border-bottom-color: transparent;
}

sub {
  position: relative;
  top: 0.5em;
  font-size: 0.8em;
}

sup {
  position: relative;
  top: -0.5em;
  font-size: 0.8em;
}

blockquote {
  border-left: solid 0.5em #ddd;
  padding: 1em 0 1em 2em;
  font-style: italic;
}

p, ul, ol, dl, table {
  margin-bottom: 1em;
}

p {
  text-align: justify;
}

br.clear {
  clear: both;
}

hr {
  position: relative;
  display: block;
  border: 0;
  top: 4.5em;
  margin-bottom: 9em;
  height: 6px;
  border-top: solid 1px rgba(128, 128, 128, 0.2);
  border-bottom: solid 1px rgba(128, 128, 128, 0.2);
}

hr:before, hr:after {
  content: '';
  position: absolute;
  top: -8px;
  display: block;
  width: 1px;
  height: 21px;
  background: rgba(128, 128, 128, 0.2);
}

hr:before {
  left: -1px;
}

hr:after {
  right: -1px;
}

.timestamp {
  color: rgba(128, 128, 128, 0.75);
  font-size: 0.8em;
}

#header {
  height: 100vh;
}

/* Container */
.container {
  margin: 0 auto;
  max-width: 100%;
  width: 1400px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


@media screen and (max-width: 1680px) {

  .container {
    width: 1200px;
  }

}

@media screen and (max-width: 1280px) {

  .container {
    width: 100%;
  }

}

@media screen and (max-width: 960px) {

  .container {
    width: 100%;
  }

}

@media screen and (max-width: 840px) {

  .container {
    width: 100%;
  }

}

@media screen and (max-width: 736px) {

  .container {
    width: 100%;
  }

}

/* Sections/Article */
section, article {
  margin-bottom: 3em;
}

section > :last-child, section:last-child, article > :last-child, article:last-child {
  margin-bottom: 0;
}

section.special > header, section.special > footer, article.special > header, article.special > footer {
  text-align: center;
}

section.special {
  padding-left: 24px;
  padding-right: 24px;
}

header > p {
  display: block;
  font-size: 1.5em;
  margin: 1em 0 2em 0;
  line-height: 1.5em;
  text-align: inherit;
}

footer {
  margin: 2.5em 0 0 0;
}

/* Image */
.image {
  position: relative;
  display: inline-block;
  border: 0;
  outline: 0;
}

.image img {
  display: block;
  width: 100%;
}

.image.fit {
  display: block;
  width: 100%;
}

.image.featured {
  display: block;
  width: 100%;
  margin: 0 0 4em 0;
}

.image.left {
  float: left;
  margin: 0 2em 2em 0;
}

.image.centered {
  display: block;
  margin: 0 0 2em 0;
}

.image.centered img {
  margin: 0 auto;
  width: auto;
}

/* List */
ul {
  list-style: none;
  padding-left: 1em;
}

ul li {
  padding-left: 0.5em;
}

ol {
  list-style: none;
  padding-left: 1.25em;
}

ol li {
  padding-left: 0.25em;
}

/* Actions */
ul.actions {
  margin: 3em 0 0 0;
  list-style: none;
  padding-left: 0;
}

form ul.actions {
  margin-top: 0;
}

ul.actions li {
  display: inline-block;
  margin: 0 0.75em 0 0.75em;
  padding-left: 0;
}

ul.actions li:first-child {
  margin-left: 0;
}

ul.actions li:last-child {
  margin-right: 0;
}

/* Divided */
ul.divided {
  list-style: none;
  padding-left: 0;
}

ul.divided li {
  border-top: solid 1px rgba(128, 128, 128, 0.2);
  padding-top: 1.5em;
  margin-top: 1.5em;
  padding-left: 0;
}

ul.divided li:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}

/* Icons */
ul.icons {
  position: relative;
  background: rgba(128, 128, 128, 0.05);
  border-radius: 4em;
  display: inline-block;
  padding: 0.35em 0.75em 0.35em 0.75em;
  font-size: 1.25em;
  cursor: default;
  list-style: none;
}

ul.icons li {
  display: inline-block;
  padding-left: 0;
}

ul.icons li a {
  display: inline-block;
  background: none;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 100%;
  border: 0;
  color: inherit;
}

ul.icons li a:hover {
  color: #2C4570;
}

/* Button */
input[type="button"],
input[type="submit"],
input[type="reset"],
button,
.button {
  position: relative;
  display: inline-block;
  background: #2C4570;
  color: #fff;
  text-align: center;
  border-radius: 0.2em;
  box-shadow: 0 20px 10px rgba(0, 17, 43, 0.3);
  text-decoration: none;
  padding: 0.65em 3em 0.65em 3em;
  border: 0;
  cursor: pointer;
  outline: 0;
  font-weight: 300;
  -moz-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
  -webkit-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
  -ms-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
button:hover,
.button:hover {
  color: #fff;
}

.button-icon {
  padding-left: 24px;
}

/* Post */

.post.stub {
  text-align: center;
}

.post.stub header {
  margin: 0;
}

/* Icons */
.icon {
  text-decoration: none;
  text-decoration: none;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

.icon:before {
  line-height: inherit;
  font-size: 1.25em;
}

.icon > .label {
  display: none;
}

.icon.solid:before {
  font-weight: 900;
}

.icon.brands:before {
  font-family: 'Font Awesome 5 Brands';
}

.icon.circled {
  position: relative;
  display: inline-block;
  background: #2b252c;
  color: #fff;
  border-radius: 100%;
  width: 3em;
  height: 3em;
  line-height: 3em;
  text-align: center;
  font-size: 1.25em;
}

header .icon.circled {
  margin: 0 0 2em 0;
}

#header-button {
  margin-bottom: 72px;
}

/* Header */
#header {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  overflow: hidden;
  text-align: center;
}

#video-container img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

#video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  background-color: #00112b;
}

#video-container video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  opacity: 1; /* maak de video x% transparant */
  animation: video 800s linear infinite; /* verminder de afspeelsnelheid van de video */
}

@keyframes video {
  from {
    transform: scale(1);
  }
  /* begint met de standaard grootte van de video */
  to {
    transform: scale(1);
  }
  /* eindigt met een licht vergrote video */
}

#video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(132, 15, 58, 2); /* voeg een semi-transparante overlaylaag toe */
}

#header header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

#header header > p {
  font-size: 1.25em;
  margin: 0;
}

#header h1 {
  color: #fff;
  font-size: 3em;
  line-height: 1em;
}

#header-tekst {
  color: #fff;
  font-size: 3em;
  line-height: 1em;
}

#header h1 a {
  color: inherit;
}

#header footer {
  margin: 0 0 0 0;
}

#logo-div {
  align-self: flex-start;
  text-align: left;
}

#logo-div img {
  width: 350px;
  height: auto;
}

body.homepage #header {
  height: 100vh;
  min-height: 40em;
}

body.homepage #header h1 {
  font-size: 4em;
}

body.homepage #header:after {
  content: '';
  background: #1C0920;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -moz-transition: opacity 2.5s ease-in-out;
  -webkit-transition: opacity 2.5s ease-in-out;
  -ms-transition: opacity 2.5s ease-in-out;
  transition: opacity 2.5s ease-in-out;
  -moz-transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

body.homepage.is-preload #header:after {
  opacity: 1;
}

/* Banner */
#banner {
  background-image: url("../img/background1.png");
  background-color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  padding: 2em 0 2em 0;
  margin-bottom: 0;
}

#banner header > p {
  margin-bottom: 0;
}

#banner-text-wrapper {
  padding-top: 48px;
  display: flex;
  gap: 48px;
}

.banner-text h3 {
  padding-bottom: 12px;
}

/* Products */
.products-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 64px;
  margin-top: 12px;
}

.product-element {
  padding: 12px;
  width: 200px;
  height: 200px;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0 20px 20px rgba(0, 17, 43, 0.3);
}

.product-text {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  transition: color 0.2s ease-in-out;
}

.product-element:hover {
  color: #2C4570;
  transform: scale(1.1);
}

/* Content */
#content > hr {
  top: 3em;
  margin-bottom: 6em;
}

#content > section {
  margin-bottom: 0;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  transition: transform 1.5s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  text-align: center;
}

.flip-card-front {
  background-size: cover;
  background-color: #00112B;
  color: #00112B;
}

.flip-card-front h1 {
  padding-top: 100px;
  color: white;
}

.flip-card-back {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #00112B;
  color: white;
  flex-wrap: wrap;
  transform: rotateY(180deg);
  padding: 20px;
}

.flip-card-back h1 {
  padding-top: 20px;
}

.flip-card-back p {
  white-space: normal;
  padding-top: 12px;
  text-align: center;
}

/* Main */
#main {
  margin-bottom: 0;
}

#main section:first-of-type {
  padding-top: 2em;
}

/* Footer */

#footer {
  position: relative;
  overflow: hidden;
  padding: 3em 0 3em 0;
  background: #00112B;
  color: #fff;
}

#footer .icon.circled {
  background: #fff;
  color: #00112B;
}

#footer header {
  text-align: center;
  cursor: default;
}

#footer h2, #footer h3, #footer h4, #footer h5, #footer h6 {
  color: #fff;
}

#footer .contact {
  text-align: center;
}

#footer .contact p {
  text-align: center;
  margin: 0 0 3em 0;
}

#footer .copyright {
  text-align: center;
  color: rgba(128, 128, 128, 0.75);
  font-size: 0.8em;
  cursor: default;
}

#footer .copyright a {
  color: rgba(128, 128, 128, 0.75);
}

#footer .copyright a:hover {
  color: rgba(212, 212, 212, 0.85);
}

/* Carousel */

.carousel {
  position: relative;
  overflow: hidden;
  padding: 2em 0 2em 0;
  margin-bottom: 0;
}

.carousel-content img {
  max-height: 200px;
  max-width: 200px;
  float: left;
  padding: 42px;
}

.carousel .forward, .carousel .backward {
  position: absolute;
  top: 50%;
  width: 6em;
  height: 12em;
  margin-top: -6em;
  cursor: pointer;
}

.carousel .forward:before, .carousel .backward:before {
  content: '';
  display: block;
  width: 6em;
  height: 6em;
  border-radius: 100%;
  background-color: #7A828F;
  position: absolute;
  top: 50%;
  margin-top: -3em;
  -moz-transition: background-color 0.35s ease-in-out;
  -webkit-transition: background-color 0.35s ease-in-out;
  -o-transition: background-color 0.35s ease-in-out;
  -ms-transition: background-color 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out;
  -webkit-backface-visibility: hidden;
}

.carousel .forward:after, .carousel .backward:after {
  content: '';
  width: 3em;
  height: 3em;
  position: absolute;
  top: 50%;
  margin: -1.5em 0 0 0;
  background: url("../img/arrow.svg") no-repeat center center;
}

.carousel .forward:hover:before, .carousel .backward:hover:before {
  background-color: rgba(44, 69, 112, 1);
}

.carousel .forward {
  right: 0;
}

.carousel .forward:before {
  right: -3em;
}

.carousel .forward:after {
  right: -0.25em;
}

.carousel .backward {
  left: 0;
}

.carousel .backward:before {
  left: -3em;
}

.carousel .backward:after {
  left: -0.25em;
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.carousel .reel {
  display: flex;
  white-space: nowrap;
  position: relative;
  -webkit-overflow-scrolling: touch;
  padding: 0 0 0 1em;
}

.carousel .carousel-article {
  flex: 1;
  min-width: 800px;
  min-height: 100%;
  background-size: cover;
  color: white;
  text-align: center;
  margin: 0 1em 0 0;
  border-radius: 10px;
  white-space: normal;
  opacity: 0.9;
  -moz-transition: opacity 0.75s ease-in-out;
  -webkit-transition: opacity 0.75s ease-in-out;
  -ms-transition: opacity 0.75s ease-in-out;
  transition: opacity 0.75s ease-in-out;
}

.carousel .flip-card-article {
  margin: 0 1em 0 0;
  min-width: 380px;
  height: 560px;
  -moz-transition: opacity 0.75s ease-in-out;
  -webkit-transition: opacity 0.75s ease-in-out;
  -ms-transition: opacity 0.75s ease-in-out;
  transition: opacity 0.75s ease-in-out;
}

.carousel .carousel-article.loading {
  opacity: 0;
}

.carousel .flip-card-article.loading {
  opacity: 0;
}

.carousel .carousel-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle 5000px at left, #00112B, #01202B);
  height: 100%;
}

.carousel .carousel-content .carousel-icon {
  font-size: 142px;
  padding: 92px;
}

.carousel .carousel-content .carousel-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 24px 24px 24px;
  height: 100%;
  text-align: center;
}

.carousel .carousel-content .carousel-text p {
  text-align: left;
}


/* Action*/
#action {
  padding-bottom: 24px;
}

/* Privacy */
#privacy-container {
  display: flex;
  flex-direction: row;
  background-color: #00112B;
}

#privacy-container header {
  display: flex;
  align-items: center;
  justify-content: right;
  flex-grow: 2;
  padding-right: 48px;
}

#privacy-container header h1 {
  color: #fff;
  font-size: 3em;
  line-height: 1em;
}

@media screen and (min-width: 2000px) {

  .carousel #flip-reel {
    justify-content: center;
  }

}

@media screen and (max-width: 1680px) {

  /* Basic */
  body, input, textarea, select {
    font-size: 14pt;
    line-height: 1.75em;
  }

  /* Carousel */
  .carousel {
    padding: 1.5em 0 1.5em 0;
  }

  .carousel .reel {
    padding: 0 0 0 1em;
  }

  .carousel .carousel-article {
    margin: 0 1em 0 0;
  }

  .carousel .flip-card-article {
    margin: 0 1em 0 0;
  }
}

/* Normal */

@media screen and (max-width: 1280px) {

  /* Basic */
  body, input, textarea, select {
    font-size: 12pt;
    line-height: 1.5em;
  }

  /* Header */
  #header {
    background-attachment: scroll;
  }

  /* Footer */
  #footer {
    padding-left: 2em;
    padding-right: 2em;
  }

  .carousel .reel {
    padding: 0 0 0 0.75em;
  }

  .carousel .carousel-article {
    margin: 0 0.75em 0 0;
  }

  .carousel .flip-card-article {
    margin: 0 0.75em 0 0;
  }
}

/* Narrow */

/* Narrower */

@media screen and (max-width: 840px) {

  /* Footer */
  #footer {
    padding: 4em 2em 4em 2em;
  }

  /* Carousel */
  .carousel {
    padding: 1.25em 0 1.25em 0;
  }

  .carousel .reel {
    padding: 0 0 0 0.75em;
  }

  .carousel .carousel-article {
    min-width: 99%;
    margin: 0 0.75em 0 0;
  }

  .carousel .flip-card-article {
    margin: 0 0.75em 0 0;
  }
}

/* Mobile */

@media screen and (max-width: 736px) {

  /* Basic */
  html, body {
    overflow-x: hidden;
  }

  body, input, textarea, select {
    font-size: 12.5pt;
    line-height: 1.5em;
  }

  h2 {
    font-size: 1.75em;
  }

  h3 {
    font-size: 1.25em;
  }

  hr {
    top: 3em;
    margin-bottom: 6em;
  }

  /* Section/Article */
  section, article {
    margin-bottom: 2em;
  }

  section:last-child, article:last-child {
    margin-bottom: 0;
  }


  header > p {
    font-size: 1.25em;
  }

  /* Image */
  .image.featured {
    margin: 0 0 2em 0;
  }

  .image.left {
    margin: 0 1em 1em 0;
  }

  .image.centered {
    margin: 0 0 1em 0;
  }

  /* Actions */
  form ul.actions {
    margin-top: 0;
  }

  ul.actions li {
    display: block;
    margin: 0.75em 0 0 0;
  }

  ul.actions li:first-child {
    margin-top: 0;
  }

  /* Menu */
  ul.menu {
    height: auto;
    text-align: center;
  }

  ul.menu li {
    display: block;
    border: 0;
    padding: 0.75em 0 0 0;
    margin: 0;
  }

  ul.menu li:first-child {
    padding-top: 0;
  }

  /* Icons */
  ul.icons {
    font-size: 1em;
    padding: 0.35em 0.5em 0.35em 0.5em;
  }

  ul.icons li a {
    width: 2.25em;
  }

  /* Button */
  input[type="button"],
  input[type="submit"],
  input[type="reset"],
  button,
  .button {
    display: block;
    padding: 0;
    line-height: 3em;
    width: 100%;
  }

  /* Header */
  #header {
    background-attachment: scroll;
  }

  #header header > p {
    font-size: 1em;
  }

  #header h1 {
    font-size: 1.75em;
  }

  #header-button {
    margin-bottom: 0;
  }

  body.homepage #header {
    min-height: 0;
    height: auto;
  }

  body.homepage #header:before {
    display: none;
  }

  body.homepage #header h1 {
    font-size: 2.5em;
  }

  #banner-text-wrapper {
    padding-top: 36px;
    flex-direction: column;
    gap: 12px;
  }

  /* Sidebar */
  #sidebar > hr:first-of-type {
    display: block;
  }

  /* Main */
  #main > header {
    text-align: center;
  }

  /* Footer */
  #footer {
    padding-left: 1em;
    padding-right: 1em;
  }

  /* Carousel */
  .carousel {
    padding: 1em 0 1em 0;
  }

  .carousel .reel {
    padding: 0 0 0 0.5em;
  }

  .carousel .carousel-article {
    padding-bottom: 2em;
    margin: 0 0.5em 0 0;
    min-width: 99%;
  }

  .carousel .flip-card-article {
    padding-bottom: 2em;
    margin: 0 0.5em 0 0;
    min-width: 99%;
  }

  .carousel .carousel-article .image {
    margin-bottom: 2em;
  }

  .carousel .carousel-content {
    flex-direction: column;
  }

  #privacy-container header h1 {
    font-size: 1.75em;
  }
}
